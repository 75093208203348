<template>
  <Page
    :title="report?.id ? $t('report-edit.title_edit') : $t('report-edit.title_new')"
    :classes="['page--report-edit', 'page--narrow']">

    <Form :classes="['form--reportnew', 'formreport']">

      <div class="flex flex--wrap">
        <div class="flex__twothirds">
          <InputEntity
            name="entity"
            :label="$t('common.entity')"
            v-model="entity"
            :filters="filters"
            :isDisabled="isEntityDisabled"
          ></InputEntity>
        </div>
        <div class="flex__third">
          <InputYear
            name="year"
            :label="$t('common.year')"
            v-model="reportYear"
            :isDisabled="isYearDisabled">
            <template #link>
              <div
                v-if="updatingReport === false && entity.last_report?.id"
                style="line-height: 20px;">
                <a @click.stop="useLastReport()">{{$t('common.use_last_report')}} ({{entity.last_report.year}})</a>
              </div>
            </template>
          </InputYear>
        </div>
      </div>

      <ReportPhasesComponent
        v-if="challengesKeys && currentKey && reportYear"
        :phases="challengesKeys"
        :currentPhase="currentKey"
        @phaseClicked="currentKey = $event"
      ></ReportPhasesComponent>

      <h2 class="formreport__phase" v-if="currentKey && reportYear">
        {{$t(`phases.${currentKey}`)}}
      </h2>

      <div v-if="currentKey && reportYear">
        <ReportChallengeComponent
          v-for="(challenge, i) in challengesBlocks[currentKey]"
          :key="`${currentKey}-${i}`"
          :entityType="entity.type"
          :challenge="challenge"
          :reportGoals="report.goals"
          :reportLocalGoals="report.local_goals"
          :reportShares="report.shares"
          :reportContacts="report.contacts"
          :reportIndicators="report.indicators"
          :reportNetworks="report.networks"
          :reportCertifications="report.certifications"
          @challengeConfirmed="challenge.isChecked = $event"
          @challengeFiled="challenge.file = $event"
          @challengeUrled="challenge.url = $event"
          @challengeSdged="challengeSdgs = $event"
          @challengeGoalsed="challengeGoals = $event"
          @challengeLocalGoalsed="challengeLocalGoals = $event"
          @challengeShared="challengeShares = $event"
          @challengeContactsed="challengeContacts = $event"
          @challengeIndicatorsed="challengeIndicators = $event"
          @challengeNetworksed="challengeNetworks = $event"
          @challengeCertificationsed="challengeCertifications = $event"
        ></ReportChallengeComponent>
      </div>

      <div class="formreport__buttons" v-if="reportYear">
        <Button
          v-if="currentIndex > 0"
          @onclick="goToPrevBlock">
          {{$t('common.previous')}}
        </Button>
        <Button
          v-if="currentIndex < challengesKeys.length - 1"
          :classes="['btn--accent']"
          @onclick="goToNextBlock">
          {{$t('common.next')}}
        </Button>
        <Button
          v-if="currentKey && currentKey === challengesKeys[challengesKeys.length-1]"
          :isLoading="isLoading"
          :isDisabled="!isValid"
          :classes="['btn--accent']"
          @onclick="createReport">
          <span v-if="!report.id">{{$t('common.create_report')}}</span>
          <span v-else>{{$t('common.update_report')}}</span>
        </Button>
      </div>

    </Form>

    <ReportHistoryComponent
      v-if="updatingReport"
      :history="reportHistory"
    />

  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import UrlService from '@/services/url/UrlService';
import ReportPhasesComponent from './components/ReportPhasesComponent.vue';
import ReportChallengeComponent from './components/ReportChallengeComponent.vue';
import ReportHistoryComponent from './components/ReportHistoryComponent.vue';

const urlService = new UrlService();

export default {
  name: 'ReportEditPage',
  components: {
    ReportPhasesComponent,
    ReportChallengeComponent,
    ReportHistoryComponent,
  },
  data() {
    return {
      entity: {},
      challengesKeys: [],
      challengesBlocks: [],
      currentKey: undefined,
      isEntityDisabled: false,
      isYearDisabled: false,
      isLoading: false,
      reportYear: null,
      reportHistory: [],
      challengeSdgs: [],
      challengeGoals: [],
      challengeLocalGoals: [],
      challengeShares: [],
      challengeContacts: [],
      challengeIndicators: [],
      challengeNetworks: [],
      challengeCertifications: [],
      updatingReport: null,
    };
  },
  computed: {
    ...mapGetters(['self', 'report', 'challenges']),
    filters() {
      return this.self.is_staff
        ? {}
        : { query: 'created' };
    },
    isValid() {
      return true;
    },
    currentIndex() {
      return this.challengesKeys.findIndex((key) => key === this.currentKey);
    },
  },
  mounted() {
    this.createBlocks();
    this.setReportEntity();
  },
  methods: {
    setReportEntity() {
      if (this.$route.query?.entity) {
        console.log('has entity');
        this.$store.dispatch('get', {
          url: 'entity',
          id: +this.$route.query.entity,
        }).then((r) => {
          this.entity = r.data;
          this.isEntityDisabled = true;
          if (this.$route.params?.year) {
            this.reportYear = +this.$route.params.year;
          }
        });
      } else if (this.self.entities.length === 1) {
        this.$store.dispatch('get', {
          url: 'entity',
          id: +this.self.entities[0].id,
        }).then((r) => {
          this.entity = r.data;
          this.$store.dispatch('updateModel', {
            model: 'entity',
            data: r.data,
          }).then(() => {
            this.isEntityDisabled = true;
            if (this.$route.params?.year) {
              this.reportYear = +this.$route.params.year;
            }
          });
        });
      }
    },
    createBlocks() {
      this.challengesBlocks = this.challenges.reduce((acc, curr) => {
        const chall = { ...curr, isChecked: false };
        if (Object.prototype.hasOwnProperty.call(acc, chall.phase)) acc[chall.phase].push(chall);
        else acc[chall.phase] = [chall];
        return acc;
      }, {});
      this.challengesKeys = Object.keys(this.challengesBlocks);
      this.challengesKeys.forEach((key) => {
        this.challengesBlocks[key] = this.challengesBlocks[key]
          .sort((a, b) => {
            if (a.is_required === b.is_required) {
              return b.id - a.id;
            }
            return a.is_required ? -1 : 1;
          });
      });
      this.currentKey = this.challengesKeys[0]; // eslint-disable-line
    },
    updateBlocks(challenges) {
      const challengesNames = challenges.map((c) => c.challenge);
      this.challengesBlocks = Object.keys(this.challengesBlocks).map((k) => this.challengesBlocks[k].map((c) => { // eslint-disable-line
        const challenge = { ...c };
        const idx = challengesNames.findIndex((name) => name === challenge.name);
        if (idx >= 0) {
          challenge.isChecked = true;
          challenge.file = challenges[idx].file;
          challenge.url = challenges[idx].url;
        } else {
          challenge.isChecked = false;
          challenge.file = undefined;
          challenge.url = undefined;
        }
        return challenge;
      })).reduce((acc, curr, i) => {
        acc[i + 1] = curr;
        return acc;
      }, {});
    },
    goToNextBlock() {
      if (this.currentIndex < this.challengesKeys.length - 1) {
        this.currentKey = this.challengesKeys[this.currentIndex + 1];
        document.getElementById('page').scrollIntoView();
      }
    },
    goToPrevBlock() {
      if (this.currentIndex > 0) {
        this.currentKey = this.challengesKeys[this.currentIndex - 1];
        document.getElementById('page').scrollIntoView();
      }
    },
    getCleanedChallenges() {
      const challenges = [];
      Object.keys(this.challengesBlocks).forEach((key) => {
        this.challengesBlocks[key]
          .filter((challenge) => challenge.isChecked)
          .forEach((challenge) => {
            challenges.push(challenge);
          });
      });
      return challenges;
    },
    getCleanedGoals() {
      const sdgs = this.challengeSdgs.map((id) => ({
        global_goal: id,
        local_goal: id,
        type: 'GOAL',
      }));
      const goals = this.challengeGoals
        .filter((goal) => goal.global_goal)
        .map((goal) => ({
          global_goal: goal.global_goal,
          local_goal: goal.global_goal,
          type: 'SUBGOAL',
        }));
      return sdgs.concat(goals);
    },
    createReport() {
      const requestData = this.report.id
        ? { id: this.report.id }
        : {};

      this.$store.dispatch(this.report.id ? 'partial_update' : 'create', {
        ...requestData,
        model: 'report',
        data: {
          ...this.report,
          entity: this.entity.id,
          challenges: this.getCleanedChallenges(),
          goals: this.getCleanedGoals(),
          local_goals: this.challengeLocalGoals
            .filter((c) => c.name !== '' && c.related_goal !== ''),
          shares: this.challengeShares
            .filter((c) => c.url !== ''),
          contacts: this.challengeContacts
            .filter((c) => c.name !== '' && c.role !== '' && c.email !== ''),
          indicators: this.challengeIndicators
            .filter((c) => c.indicator),
          networks: this.challengeNetworks
            .filter((c) => c.name !== '' && c.url !== ''),
          certifications: this.challengeNetworks
            .filter((c) => c.name !== '' && c.url !== '' && c.year !== ''),
        },
      }).then(() => {
        this.$store.dispatch('meeseeks', {
          type: 'success',
          message: this.$t('common.report_created'),
        });
        if (!this.report.id) {
          this.$store.dispatch('getSelf');
        }
        this.$store.dispatch('retrieve', {
          model: 'entity',
          id: this.entity.id,
          updateModel: true,
        }).then(() => {
          this.$router.push(urlService.entityRoute(this.entity, this.$i18n.locale));
        });
      }).catch(() => {
        this.$store.dispatch('meeseeks', {
          type: 'error',
          message: this.$t('error.cant_update_data'),
        });
      });
    },
    retrieveReport(year) {
      this.$store.dispatch('list', {
        model: 'report',
        params: { entity: this.entity.id, year },
      }).then((response) => {
        if (response.data?.count) {
          this.updatingReport = true;
          this.$store.dispatch('updateModel', { model: 'report', data: response.data.results[0] });
          this.updateBlocks(response.data.results[0].challenges);
          this.reportHistory = response.data.results[0].history;
        } else {
          this.updatingReport = false;
          this.$store.dispatch('emptyModel', 'report');
          this.report.year = year;
          this.updateBlocks([]);
          this.reportHistory = [];
        }
      }).catch((e) => {
        console.error(e);
        this.$store.dispatch('meeseeks', {
          message: this.$t('error.cant_retrieve_data'),
          type: 'error',
        });
      });
    },
    useLastReport() {
      this.updatingReport = null;
      const data = {
        ...this.entity.last_report,
        year: this.reportYear,
      };
      delete data.id;
      this.$store.dispatch('updateModel', { model: 'report', data });
      this.updateBlocks(this.entity.last_report.challenges);
    },
  },
  watch: {
    reportYear(year) {
      if (year) {
        this.retrieveReport(year);
        this.$router.push({ params: { locale: this.$i18n.locale, year } });
      } else {
        this.updatingReport = null;
        this.reportHistory = [];
      }
    },
    $route(route) {
      if (route.params?.year) this.reportYear = +route.params.year;
      else this.reportYear = null;
    },
  },
};
</script>

<style lang="scss">
.formreport {
  .input-block__alerts {
    display: none;
  }
  &__phase {
    margin-bottom: 40px;
  }
  &__buttons {
    text-align: center;
    margin-top: 20px;
  }
  .input-block .inputselect {
    &__options {
      ul {
        max-height: 130px !important;
      }
    }
  }
}
</style>
